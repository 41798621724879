@keyframes fadeBalanceToResto {
  0% {
    background: url('../../../public/static/images/talents/balance.png') no-repeat center
      center/cover;
  }
  50% {
    background: url('../../../public/static/images/talents/resto.png') no-repeat center center/cover;
  }
  100% {
    background: url('../../../public/static/images/talents/resto.png') no-repeat center center/cover;
  }
}

@keyframes fadeRestoToBalance {
  0% {
    background: url('../../../public/static/images/talents/resto.png') no-repeat center center/cover;
  }
  50% {
    background: url('../../../public/static/images/talents/balance.png') no-repeat center
      center/cover;
  }
  100% {
    background: url('../../../public/static/images/talents/balance.png') no-repeat center
      center/cover;
  }
}

.bg-fade-balance {
  animation: fadeRestoToBalance 2s forwards;
  background-size: cover;
}

.bg-fade-resto {
  animation: fadeBalanceToResto 2s forwards;
  background-size: cover;
}

.border-balance {
  border-color: #184118;
}

.border-resto {
  border-color: #4b62be;
}

.border-generic {
  border-color: #d57f43;
}

.border-dotc {
  border-color: #c41e3a;
}

.bg-fade-generic {
  background:
    linear-gradient(rgba(73, 44, 23, 0.1), rgba(117, 70, 37, 0.7)),
    url('../../../public/static/images/talents/generic.png') no-repeat center center/cover;
  background-size: cover;
  background-color: #8b4f2b;
}
