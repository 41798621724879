/* image.module.css */
.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.fullScreen img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: zoom-out; /* change cursor to indicate you can zoom out */
}
